
const namespaced = true

import api from '../../api/attachments.js';

const state = {
    uploadState: false,
}
const mutations = {
    SET_STATUS(state, val){
        state.uploadState = val;
    },
}
const actions = {
    uploadFile(context, {config, file}){
        context.dispatch('legal/setBanner', true, { root : true });    
        api.upload({config, file }).then(async function (res) {
            if(res.status == 200){
                await context.dispatch('notifications/add', {'color' : 'success',  'visibility' : true,  'timeout' : 5000, 'text' : 'Archivo cargado'}, {root: true });
                await context.dispatch('setInformation', {'data': res.data , 'destination': {'module': config.entity, 'state': config.property}});
                await context.dispatch('legal/setBanner', false, { root : true }); 
            }
        }).catch(function (error) {
            console.log(error)
             context.dispatch('legal/setBanner', true, { root : true }); 
        })
    },
    setInformation(context, {data, destination}){
        const dictionary = { 
            'legal': () => {
                context.dispatch('legal/setLegalCases', data, { root : true });    
            }
        }
        return dictionary[destination.module] ? dictionary[destination.module]() : false;
    }
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
