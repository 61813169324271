import _ from "lodash";
import moment from 'moment';

export function kbbtsToMbs (kbts){
    var rs =  ((parseInt (kbts)) / 1024)/1024;    
    return rs.toFixed(2);
}

export function number (value) {
    return _.floor(value, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function date (date,format = 'L'){
    return date?moment(date).format(format):'';
}

export function dateLong(value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm:ss a');
}