import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
    visible: false,
  },
  {
    path: '/home',
    name: 'home',
    visible : false,
    meta :{
      title: 'Inicio',
    }
  },
  {
    path: '/users',
    name: 'users',
    visible: true,
    meta: {
      title: 'Administración / Usuarios, Permisos y Roles',
      permissions: [
        'get-users', 'store-users', 'update-users', 'delete-users',
        'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
        'get-roles', 'store-roles', 'update-abilities', 'delete-abilities','warehouse-to-user'
      ],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    visible: true,
    meta: { 
        title: 'Configuraciones / Generales',
        permissions: ['settings-list'],
    },
  },
  {
    path: '/legal',
    name: 'legal',
    visible: true,
    meta: { 
        title: 'Asuntos legales / Generales',
        permissions: ['get-legal-matters', 'store-legal-matters', 'update-legal-matters'],
    },
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes
})

export default router