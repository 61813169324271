import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
import roles                from  './modules/roles.js';
import activities           from './modules/activities.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import location             from './modules/location.js';
import company              from './modules/company.js';
import attachments          from './modules/attachments.js';
import legal                from './modules/legal.js';
import log                  from './modules/log.js';

const dataState = createPersistedState({
  paths: ['configuration','activities']
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    maps,
    user,
    roles,
    activities,
    location,
    company,
    attachments,
    legal,
    log
  },
  plugins: [dataState]
})