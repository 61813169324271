const namespaced = true

import api from '../../api/log.js';

const state = {
     activity:[],
}
const mutations = {
     // Name, Property
    MUTATION_SET_ACTIVITY(state, data){
         state.activity = data;
    }
}
const actions = {
     // Name Property
    getActivity(context,{ entity = null}) {
        api.getActivity(entity).then(function (res) {
            context.commit('MUTATION_SET_ACTIVITY', res.data);            
       }).catch(function (error) {
            console.log(error)
       })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}