const namespaced = true
import _ from 'lodash';
import api from '../../api/legalCase.js';

const state = {
    legalcases: [],
    legalcasesTemporal: [],
    legalcasesPreview: [],
    legalcasesPreviewTemporal: [],
    filterActive: "",
    bannerUpload: false,
    bannerGet: false,
    bannerStore: false,
    action: "index",

}
const mutations = {
    MUTATION_SET_LEGAL_CASES(state, data) {
        // Rules 
        // 1) Skip 3 first rows, because this is for the user's control
        // 2) if first column is null ignore this row, only get value from second column.
        // 3) if second column is null ignore this row.
        var type = '';
        var tmp = [];

        //data.splice(0, 3); // Rule #1
        data.map(x => {
            if (x['no'] == 0 && x['description'] != null) { // Rule #2
                type = x['description'];
                return false;
            }
            if (x['description'] == null) { // Rule #3
                return false;
            }
            tmp.push({
                'tipo': type,
                'no': x['no'],
                'tarea': x['description'],
                'empresa': x['company'],
                'materia': x['type'],
                'estado': x['state'],
                'prioridad': x['priority'],
                'asignado': x['assigned'],
                'inicio': x['date_initial'],
                'final': x['date_final'],
                'tiempo': x['estimated_time'],
                'valor': x['cost'],
                'notas': x['notes']
            })
        });
        state.legalcasesPreview = tmp;
        state.legalcasesPreviewTemporal = tmp;
    },
    MUTATION_SET_LEGAL_CASES_FILTERS(state, data) {
        (state.action == 'index') ? state.legalcases = data : state.legalcasesPreview = data;

    },
    MUTATION_SET_LEGAL_CASES_FILTER_ACTIVE(state, value) {
        state.filterActive = value;
    },
    MUTATION_SET_LEGAL_CASES_GET(state, data) {
        var tmp = [];
        data.map(x => {
            tmp.push({
                'tipo': x['type'],
                'no': x['no'],
                'tarea': x['description'],
                'empresa': x['company'],
                'materia': x['subject'],
                'estado': x['state'],
                'prioridad': x['priority'],
                'asignado': x['assigned'],
                'inicio': x['date_initial'],
                'final': x['date_final'],
                'tiempo': x['estimated_time'],
                'valor': x['cost'],
                'notas': x['notes']
            })
        });
        state.legalcasesTemporal = tmp;
        state.legalcases = tmp;
    },
    MUTATION_SET_BANNER_GET(state, value) {
        state.bannerGet = value;
    },
    MUTATION_SET_BANNER_UPLOAD(state, value) {
        state.bannerUpload = value;
    },
    MUTATION_SET_ACTION(state, value) {
        state.action = value;
    },
    MUTATION_RESET_PREVIEW(state, value) {
        state.legalcasesPreview = value;
        state.legalcasesPreviewTemporal = value;
    },
    MUTATION_SET_BANNER_STORE(state, value) {
        state.bannerStore = value;
    },
}
const actions = {
    // Name Property
    setLegalCases(context, data) {
        context.commit('MUTATION_SET_LEGAL_CASES', data);
    },
    filters(context, type) {
        let data = (state.action == 'index') ? state.legalcases : state.legalcasesPreview;
        let dataTemporal = (state.action == 'index') ? state.legalcasesTemporal : state.legalcasesPreviewTemporal;
        if (type == context.state.filterActive) {
            context.commit('MUTATION_SET_LEGAL_CASES_FILTERS', dataTemporal);
            context.commit('MUTATION_SET_LEGAL_CASES_FILTER_ACTIVE', "");
        } else {
            context.commit('MUTATION_SET_LEGAL_CASES_FILTER_ACTIVE', type);
            let tempo = _.filter(data, function (o) {
                return o.estado == type;
            });
            context.commit('MUTATION_SET_LEGAL_CASES_FILTERS', tempo);
        }
    },
    getLegalCase(context) {
        context.commit('MUTATION_SET_BANNER_GET', true);
        return new Promise((resolve) => {
            api.getLegalCase().then(function (res) {
                context.commit('MUTATION_SET_LEGAL_CASES_GET', res.data);
                context.commit('MUTATION_SET_BANNER_GET', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_BANNER_GET', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store(context, data) {
        context.commit('MUTATION_SET_BANNER_STORE', true);
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                context.commit('MUTATION_SET_LEGAL_CASES_GET', res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Asuntos legales registrados' }, { root: true });
                resolve(res.data);
                context.commit('MUTATION_SET_BANNER_STORE', false);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                context.commit('MUTATION_SET_BANNER_STORE', false);

            })
        })
    },
    setBanner(context, value) {
        context.commit('MUTATION_SET_BANNER_UPLOAD', value);
    },
    setAction(context, value) {
        context.commit('MUTATION_SET_ACTION', value);
    },
    resetPreview(context) {
        context.commit('MUTATION_RESET_PREVIEW', []);
    },
}

const getters = {
    totalLegalCases: (state) => {
        return (state.action == 'index') ? state.legalcases.length : state.legalcasesPreview.length;
    },
    summaryByEstado: (state) => {
        let data = (state.action == 'index') ? state.legalcases : state.legalcasesPreview;
        const estadoCount = {};

        for (const caso of data) {
            const estado = caso.estado;
            if (estadoCount[estado]) {
                estadoCount[estado]++;
            } else {
                estadoCount[estado] = 1;
            }
        }
        const estadoCountArray = Object.keys(estadoCount).map(estado => ({
            nombre: estado,
            valor: estadoCount[estado]
        }));

        return estadoCountArray;
    },
    summaryByTipo: (state) => {
        let data = (state.action == 'index') ? state.legalcases : state.legalcasesPreview;
        const tipoCount = {};

        for (const caso of data) {
            const tipo = caso.tipo;
            if (tipoCount[tipo]) {
                tipoCount[tipo]++;
            } else {
                tipoCount[tipo] = 1;
            }
        }

        const tipoCountArray = Object.keys(tipoCount).map(tipo => ({
            nombre: tipo,
            valor: tipoCount[tipo]
        }));

        return tipoCountArray;
    }

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}