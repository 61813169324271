<template>    
    <v-app>
        
        <navigation-menu v-if="currentRoute != 'login'"></navigation-menu>
        <v-main>
            <router-view v-if="canSeeView()"></router-view>
            <PageNotFound v-else></PageNotFound>
        </v-main>      

        <v-snackbar bottom right outlined multi-line style="white-space: pre-line" v-model="notification.visibility" :color="notification.color" :timeout="notification.timeout">
            {{ notification.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="notification.visibility = false" :color="notification.color">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>        
    </v-app>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import NavigationMenu from './shared/NavigationMenu.vue';
    import PageNotFound from './shared/PageNotFound.vue';
    import { can } from '../src/helpers/permissions';

    export default {
        name: 'App',
        components: {
            NavigationMenu,
            PageNotFound
        },
        data: () => ({}),
        methods: {
            ...mapActions('authentication', ['check_token']),
            ...mapActions('notifications',  ['clear']),            
            /**
             * Solo en el caso en que quisieramos ocultarlo en una determinada ruta.
             */
            isShow(){
                return (this.$route.fullPath != '/') ? true : false;
            },
            canSeeView(){
                return (this.$route.meta.permissions)?can(this.getAbilitiesUser, this.$route.meta.permissions):true;
            },
        },
        computed: {
            ...mapGetters('configuration', ['getAbilitiesUser']),
            ...mapState('notifications', ['notification']),
            ...mapState('authentication', ['isLoggedIn']),
            currentRoute(){
                return this.$route.name;
            },
        },
        beforeMount (){
            this.clear();
            this.check_token();
        },
        watch : {
            isLoggedIn : function (nvl){
                if(this.$route.fullPath != '/login'){
                    nvl == false ? this.$router.push({'name' : "login"}) : void(0);
                }
            },
        }
    }
</script>
