const namespaced = true
import api from '../../api/company.js';

const state = {
    companies: [],
}
const mutations = {
    MUTATION_SET_COMPANIES(state, data) {
        state.companies = data;
    }
}
const actions = {
    getCompanies(context) {
        return new Promise((resolve) => {
            api.getCompanies().then(function (res) {
                context.commit('MUTATION_SET_COMPANIES', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store(context,data) {
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                context.commit('MUTATION_SET_COMPANIES', res.data);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Compañia creado correctamente' }, { root: true });
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    update(context,obj) {
        return new Promise((resolve) => {
            api.update(obj.id, obj.data).then(function (res) {
                context.commit('MUTATION_SET_COMPANIES', res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Compañia actualizada correctamente' }, { root: true });
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
}

const getters = {

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}