import { isNull } from "lodash";

let prefix = '/api/log';

function getActivity(entity){
    if(isNull(entity)){
        return window.axios.get(prefix+'/get');
    }
    return window.axios.get(prefix+'/get?entity='+entity);
}
export default {
    getActivity
}