
let prefix = '/api/MattersLegal';

function getLegalCase() {
    return window.axios.get(prefix + '/index');
}
function store(data) {
    return window.axios.post(prefix + '/store', data);
}



export default {
    getLegalCase, store

}